import CancelIcon from '@mui/icons-material/Cancel';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import parse from 'html-react-parser';
import React, { useContext, useEffect, useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import { FaRegSave, FaVideo } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-tabs-scrollable/dist/rts.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UDContext } from '../../../../App';
import { BaseUrlAPI, ImageUpload, VideoUpload, getNewsInfo, postNewsInfoInsert, postNewsUpdate } from '../../../BaseModels/MasterData';
import ModalPopup from '../../../CustomElements/ModalPopup/ModalPopup';
import BackendTopMenu from '../../BackendTopMenu/BackendTopMenu';
import ImageLogo from './../../../../images/ImageLogo.svg';
import st from './SaveNews.module.css';

const toolbarOptions = {
    toolbar: {
        container: [
            [{ color: [] }, { background: [] }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
            ["link", "image", "video"],
            ["clean"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["bold", "italic", "underline"],
        ],
    }
}

const Image = ({ src, alt, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            <img src={src} alt={alt} {...props}
                style={loaded ? { ...props.style } : { ...props.style, display: 'none' }}
                onLoad={() => { setLoaded(true); }} />
            {loaded ? null : <img src={ImageLogo} alt={alt} {...props} />}
        </>

    );
};

const SaveNews = () => {
    const { news_id } = useParams();
    const navigate = useNavigate();
    const UserDetail = useContext(UDContext);
    const [AllNewsData, setAllNewsData] = useState([]);
    let [NewsDataOld, setNewsDataOld] = useState({});
    let [NewsData, setNewsData] = useState();
    // const [SelectedDesignId, setSelectedDesignId] = useState(0);
    const [NewsTitle, setNewsTitle] = useState("");
    const [NewsDescription, setNewsDescription] = useState("");
    const [NewsImageLink, setNewsImageLink] = useState("");
    const [NewsDate, setNewsDate] = useState("");
    const [NewsVideoLink, setNewsVideoLink] = useState("");
    const [IsNewsVideoUploading, setIsNewsVideoUploading] = useState(false);
    const [NewsType, setNewsType] = useState(false);
    const [IsNewsPreviewVisible, setIsNewsPreviewVisible] = useState(false);
    const [IsVideoVisible, setIsVideoVisible] = useState(false);
    const [IsSaveButtonClick, setIsSaveButtonClick] = useState(false);
    const [VideoUrl, setVideoUrl] = useState("");
    useEffect(() => {
        if (UserDetail !== null) {
            getAllNews();
        }
    }, [UserDetail]);

    const getAllNews = async () => {
        getNewsInfo().then((response) => {
            if (response.status) {
                if (response.data.length > 0) {
                    setAllNewsData(response.data);
                } else {
                    setAllNewsData([]);
                }
            }
        });
    }


    useEffect(() => {
        if (String(news_id) === "0") {
            setNewsTitle('');
            setNewsDescription('');
            setNewsImageLink("")
            setNewsVideoLink("");
            const cd = new Date();
            // let dateFormater = cd.getDate() + "-" + (cd.getMonth() + 1) + "-" + cd.getFullYear() + " " + cd.getHours() + ":" + cd.getMinutes();
            let dateFormater = cd.getDate() + "/" + (cd.getMonth() + 1) + "/" + cd.getFullYear();
            setNewsDate(dateFormater)
            setNewsType(false);
        } else {
            AllNewsData.forEach((news, index) => {
                if (String(news_id) === String(news.news_id)) {
                    setNewsDataOld(news);
                    setNewsData(news);
                    setNewsTitle(news.title);
                    setNewsDescription(news.description);
                    setNewsImageLink(news.image)
                    setNewsVideoLink(news.video);
                    setNewsType(news.news_type === "HighLevel" ? true : false);
                    setNewsDate(news.created_date)
                }
            });
        }
    }, [AllNewsData, news_id]);


    const NewsImage_onChange = async (file) => {
        try {
            if (file) {
                if (file.size > 1024 && file.size < (1024 * 1024) * 5) {//1kb to 5mb
                    ImageUpload(file).then(res => {
                        if (res.status) {
                            setNewsImageLink(res.data);
                        } else {
                            toast.error(res.message);
                        }
                    });
                } else {
                    toast.error("Image size should be between 1kb to 5mb");
                }
            }
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    const NewsVideo_onChange = async (file) => {
        try {
            if (file) {
                if (file.size > 1024 && file.size < (1024 * 1024) * 50) {//1kb to 50mb
                    setIsNewsVideoUploading(true);
                    VideoUpload(file).then(res => {
                        if (res.status) {
                            setNewsVideoLink(res.data);
                        } else {
                            toast.error(res.message);
                        }
                        setIsNewsVideoUploading(false);
                    });
                } else {
                    toast.error("Image size should be between 1kb to 50mb");
                }
            }
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    useEffect(() => {
        let _NewsData = {
            ...NewsData,
            "news_id": news_id,
            "title": NewsTitle,
            "image": NewsImageLink,
            "video": NewsVideoLink,
            "description": NewsDescription,
            "selected_design_id": 0,
            "public": true,
            "news_type": NewsType ? "HighLevel" : "LowLevel"
        }
        setNewsData(_NewsData);
    }, [NewsTitle, NewsDescription, NewsImageLink, NewsVideoLink]);


    const btnSave_onClick = async () => {
        setIsSaveButtonClick(true);
        let obj = {
            "news_id": news_id,
            "title": NewsTitle,
            "image": NewsImageLink,
            "video": NewsVideoLink,
            "description": NewsDescription,
            "selected_design_id": 0,
            "public": true,
            "news_type": NewsType ? "HighLevel" : "LowLevel"
        }
        let _AllNewsData = [...AllNewsData];
        let _AllNewsData_HighLevel = _AllNewsData.filter((news) => news.news_type === "HighLevel");
        if (String(news_id) === "0") {
            if (_AllNewsData_HighLevel.length >= 5 && NewsType) {
                toast.error("Only 5 landing page announcement is allowed");
                return;
            }
            postNewsInfoInsert(obj).then((response) => {
                if (response.status) {
                    toast.success(response.data);
                    navigate("/backend/news")
                } else {
                    toast.error(response.message);
                }
                setIsSaveButtonClick(false);
            });
        } else {
            if (_AllNewsData_HighLevel.length >= 5 && NewsType) {
                if (NewsDataOld.news_type === "HighLevel") {

                } else {
                    toast.error("Only 5 landing page announcement is allowed");
                    return;
                }
            }
            postNewsUpdate(obj).then((response) => {
                if (response.status) {
                    toast.success(response.data);
                    navigate("/backend/news")
                } else {
                    toast.error(response.message);
                }
                setIsSaveButtonClick(false);
            });
        }
    }

    const btnVideoShow_onClick = () => {
        toast.info("For preview video, Please go below popup.");
        // getBase64(BaseUrlAPI + "videos" + NewsVideoLink + "?token=" + UserDetail?.user?.token).then((data) => {
        //     setVideoUrl(data);
        //     if (!IsVideoVisible)
        //         setIsVideoVisible(true);
        // });
    }


    return (
        <>
            <BackendTopMenu />
            <div className="mainContainer">
                <div className="row mt-2">
                    <div className="col-lg-6">
                        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                            <Link className={st.navigateItem} to={"/backend"}>Admin Home</Link>
                            <Link className={st.navigateItem} to={"/backend/news"}>Announcement</Link>
                            <Link className={st.navigateItem} to={"/backend/news/" + news_id}>{String(news_id) === "0" ? "New" : news_id}</Link>
                        </Breadcrumbs>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-6">
                        <div className={st.pageTitle}>Announcement Detail</div>
                    </div>
                    <div className="col-lg-6">
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className={st.formContainer}>
                            <div className={st.formLableContainer}>
                                <div className={st.formLabelName}>Title</div>
                                <span className={st.formRequied}>*</span>
                            </div>
                            <div className={st.formInputContainer}>
                                <input type="text" name='title' placeholder="Title" className={st.formInput}
                                    value={NewsTitle} onChange={(e) => setNewsTitle(e.target.value)} />
                                {/* <ReactQuill value={NewsTitle}
                                    theme="snow"
                                    modules={toolbarOptions}
                                    onChange={setNewsTitle}
                                    className={`${st.inputCommentData} ${"ReactQuill-news-title"}`}></ReactQuill> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className={st.formContainer}>
                            <div className={st.formLableContainer}>
                                <div className={st.formLabelName}>Design</div>
                                <span className={st.formRequied}>*</span>
                            </div>
                            <div className={st.formInputContainer}>
                                <ReactQuill value={NewsDescription}
                                    theme="snow"
                                    modules={toolbarOptions}
                                    onChange={setNewsDescription}
                                    className={`${st.inputCommentData} ${"ReactQuill-news-description"}`}></ReactQuill>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className={st.formContainer}>
                            <div className={st.formLableContainer}>
                                <div className={st.formLabelName}>Image</div>
                            </div>
                            {
                                NewsImageLink !== "" ? <>
                                    <div className={st.imagePreviewContainer}>
                                        <Image src={BaseUrlAPI + "images" + NewsImageLink + "?token=" + UserDetail?.user?.token}
                                            alt="News Image" className={st.previewImage} />
                                        <div className={st.removeImagePreview} onClick={() => setNewsImageLink("")}>
                                            <IoIosCloseCircle />
                                        </div>
                                    </div>
                                </> : <>
                                    <div className={st.formInputContainer}>
                                        <div className={st.formInputFileUploadContainer}>
                                            <div className={st.formInputFileUpload}>
                                                <FileUploader handleChange={NewsImage_onChange} name="file"
                                                    lable="Upload or drop a file right here" types={["PNG", "GIF", "JPG", "JPEG"]} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }


                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className={st.formContainer}>
                            <div className={st.formLableContainer}>
                                <div className={st.formLabelName}>Video</div>
                            </div>
                            {
                                IsNewsVideoUploading ? <><CircularProgress /></> : <>
                                    {
                                        NewsVideoLink !== "" ? <>
                                            <div className={st.imagePreviewContainer}>
                                                <video className={`${st.previewImage} ${"removeVideoProgress"}`} controls>
                                                    <source src={BaseUrlAPI + "videos" + NewsVideoLink + "?token=" + UserDetail?.user?.token} type="video/mp4" />
                                                </video>
                                                <div className={st.removeImagePreview} onClick={() => { setNewsVideoLink(""); setIsNewsPreviewVisible(false); }}>
                                                    <IoIosCloseCircle />
                                                </div>
                                            </div>
                                        </> : <>
                                            <div className={st.formInputContainer}>
                                                <div className={st.formInputFileUploadContainer}>
                                                    <div className={st.formInputFileUpload}>
                                                        <FileUploader handleChange={NewsVideo_onChange} name="file"
                                                            lable="Upload or drop a file right here" types={["MP4"]} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-6">
                        <FormControlLabel control={<Checkbox
                            checked={NewsType}
                            onChange={(e) => setNewsType(e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />} label="It will show in the landing page" />
                    </div>
                    <div className="col-lg-6">
                        <div className={st.topActionButtonContainer}>
                            <Button onClick={() => navigate("/backend/news")} variant="outlined"
                                endIcon={<CancelIcon />} size="small">
                                Cancel
                            </Button>
                            <Button onClick={() => setIsNewsPreviewVisible(true)} variant="contained"
                                disabled={NewsTitle === ""}
                                endIcon={<RemoveRedEyeIcon />} size="small">
                                Preview
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <ModalPopup IsVisible={IsNewsPreviewVisible} onClose={() => setIsNewsPreviewVisible(false)}>
                <div className={st.modelPreviewContainer}>
                    <div className={st.modelPreviewHeaderContainer}>
                        <div>Preview</div>
                        <div>
                            <IconButton onClick={() => setIsNewsPreviewVisible(false)}>
                                <IoIosCloseCircle />
                            </IconButton>
                        </div>
                    </div>
                    <div className={st.modelPreviewBodyContainer}>
                        <div className={`${st.newsPreviewContainer} ${"row"}`}>
                            <div className={NewsImageLink !== "" ? "col-sm-12 col-lg-8" : "col-sm-12 col-lg-12"}>
                                <div className={st.newsTitle}>
                                    {NewsTitle}
                                </div>
                                <div className={st.mediasContainer}>
                                    <div>{NewsDate}</div>
                                    {
                                        NewsVideoLink === "" ? "" :
                                            <>
                                                <div className={st.split}>|</div>
                                                <Button size="small" startIcon={<FaVideo />} onClick={() => btnVideoShow_onClick()}>
                                                    Video
                                                </Button>
                                            </>
                                    }
                                </div>
                                <div className={st.newsDescription}>
                                    {parse(NewsDescription)}
                                </div>
                            </div>
                            <div className={NewsImageLink !== "" ? "col-sm-12 col-lg-4" : ""}>
                                {
                                    NewsImageLink !== "" ? <>
                                        <Image src={BaseUrlAPI + "images" + NewsImageLink + "?token=" + UserDetail?.user?.token}
                                            alt="News Image" className={st.newsMedia} />
                                    </> : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className={st.modelPreviewFooterContainer}>
                        <div>

                        </div>
                        <Button variant="contained" endIcon={<FaRegSave />} size="small" disabled={IsSaveButtonClick} onClick={btnSave_onClick}>
                            Publish
                        </Button>
                    </div>
                </div>
            </ModalPopup>
            <ModalPopup IsVisible={IsVideoVisible} onClose={() => setIsVideoVisible(false)}>
                <div className={st.mediaContainer}>
                    <video controls className={st.mediaVideo}>
                        <source src={VideoUrl} type="video/mp4" />
                    </video>
                </div>
            </ModalPopup>
            <br />
            <br />
            <br />
        </>
    );
};

export default SaveNews;