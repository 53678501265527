import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import { FaEdit, FaExternalLinkSquareAlt, FaEye, FaRegSave } from "react-icons/fa";
import { IoIosAddCircle, IoIosCloseCircle, IoMdTrash } from "react-icons/io";
import { SiAffinitydesigner } from "react-icons/si";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UDContext } from '../../../../App';
import ModalPopup from '../../../CustomElements/ModalPopup/ModalPopup';
import BackendTopMenu from '../../BackendTopMenu/BackendTopMenu';
import ImageLogo from './../../../../images/ImageLogo.svg';
import {
    BaseUrlAPI, ImageUpload, getTabInfo, postPageInfoDelete, postPageInformationInsert,
    postPageSequence,
    postPageUpdate,
    postSectionInfoDelete,
    postSectionSequence,
    postSectionUpdate,
    postSectioninfoInsert
} from './../../../BaseModels/MasterData';
import { useContainerDimensions } from './../../../BaseModels/ResizeWindow';
import st from './PageList.module.css';


const Image = ({ src, alt, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            <img src={src} alt={alt} {...props}
                style={loaded ? {} : { display: 'none' }}
                onLoad={() => { setLoaded(true); }} />
            {loaded ? null : <img src={ImageLogo} alt={alt} {...props} />}
        </>
    );
};

const PageList = () => {
    const UserDetail = useContext(UDContext);
    const { width, height } = useContainerDimensions()
    const navigate = useNavigate();
    const [PageList, setPageList] = useState();
    const [PageSaveScreenVisible, setPageSaveScreenVisible] = useState(false);
    const [SelectedPage, setSelectedPage] = useState({
        "page_id": 0,
        "page_name": "",
        "home_flag": false,
        "slug": "",
        "icon": "",
        "page_title": "",
        "seq": 0,
        "section": []
    });
    const [PageErrorMessage, setPageErrorMessage] = useState([])
    const [SectionList, setSectionList] = useState();
    const [SectionSaveScreenVisible, setSectionSaveScreenVisible] = useState(false);
    const [SelectedSection, setSelectedSection] = useState({
        "section_id": 0,
        "page_id": 0,
        "section_name": "",
        "icon": "",
        "seq": 0,
        "report": []
    });
    const [Page_GridApi, setPage_GridApi] = useState(null);
    const [Section_GridApi, setSection_GridApi] = useState(null);
    const [Page_NewSequence, setPage_NewSequence] = useState([]);
    const [Section_NewSequence, setSection_NewSequence] = useState([]);
    const containerStyle = useMemo(() => ({ width: '100%', height: "330px", minHeight: '300px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

    const page_columns = [
        { headerName: 'Page Id', field: 'page_id', rowDrag: true,width:120 },
        { headerName: 'Page Name', field: 'page_name', },
        {
            headerName: 'Slug',
            field: 'slug',
            cellRenderer: (props) => {
                return (
                    <>
                        <div className={st.slugContainer}>
                            <span>{props.value}</span>
                            <FaExternalLinkSquareAlt
                                style={String(props.value) === String("home") ? { display: 'none' } : {}}
                                className={st.slugLink}
                                onClick={() => { navigate("/page/" + props.value) }} />
                        </div>
                    </>
                )
            },
        },
        {
            headerName: 'Icon',
            field: 'icon',
            cellRenderer: (props) => {
                return (
                    <>
                        <div className={st.pageTableIconContainer} style={
                            props.value === '' ? { display: 'none' } : {}
                        }>
                            <Image src={BaseUrlAPI + "images" + props.value + "?token=" + UserDetail?.user?.token} alt="Icon" className={st.pagetableIcon} />
                        </div>
                    </>
                )
            },
            sortable: false,
            filter: false,
            width:120
        },
        {
            headerName: 'Actions',
            field: 'page_id',
            cellRenderer: (props) => {
                return (
                    <>
                        <div className={st.tableActionButtonContainer}>
                            <div className={st.btnActionContainer}>
                                <Tooltip title="Design Page" placement="top">
                                    <IconButton onClick={() => { navigate(`/backend/pageList/${props.value}`) }} className={st.btnAction}>
                                        <SiAffinitydesigner />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div className={st.btnActionContainer} style={String(props.value) === String("1") ? { display: 'none' } : {}}>
                                <Tooltip title="Edit" placement="top">
                                    <IconButton onClick={() => { btnEditPage_onClick(props.value) }} className={st.btnAction}>
                                        <FaEdit />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div className={st.btnActionContainer} style={String(props.value) === String("1") ? { display: 'none' } : {}}>
                                <Tooltip title="View" placement="top">
                                    <IconButton onClick={() => { btnViewPage_onClick(props.value) }} className={st.btnAction}>
                                        <FaEye />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div className={st.btnActionContainer} style={String(props.value) === String("1") ? { display: 'none' } : {}}>
                                <Tooltip title="Delete" placement="top">
                                    <IconButton onClick={() => { btnDeletePage_onClick(props.value) }} className={st.btnAction}>
                                        <IoMdTrash />
                                    </IconButton>
                                </Tooltip>
                            </div>

                        </div>
                    </>
                )
            },
            sortable: false,
            filter: false,
            width: 150,
            pinned: 'right'
        },

    ];
    const section_columns = [
        { headerName: 'Section Id', field: 'section_id', rowDrag: true },
        { headerName: 'Section Name', field: 'section_name' },
        {
            headerName: 'Actions',
            field: 'section_id',
            cellRenderer: (props) => {
                return (
                    <>
                        <div className={st.tableActionButtonContainer}>
                            <div className={st.btnActionContainer}>
                                <Tooltip title="Edit" placement="top">
                                    <IconButton onClick={() => { btnEditSection_onClick(props.value) }} className={st.btnAction}>
                                        <FaEdit />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div className={st.btnActionContainer}>
                                <Tooltip title="Delete" placement="top">
                                    <IconButton onClick={() => { btnDeleteSection_onClick(props.value) }} className={st.btnAction}>
                                        <IoMdTrash />
                                    </IconButton>
                                </Tooltip>
                            </div>

                        </div>
                    </>
                )
            },
            sortable: false,
            filter: false,
            width: 100,
            pinned: 'right'
        }
    ];
    const defaultColDef = useMemo(() => {
        let obj = {
            width: 170,
            filter: true,
            editable: false,
            sortable: true
        }
        if (width > 992) {
            obj.flex = 1;
        }
        return obj;
    }, []);

    const Page_onGridReady = (params) => {
        setPage_GridApi(params.api);
    };

    const Page_RowDragEnd = useCallback((e) => {
        let _Page_NewSequence = [];
        Page_GridApi.forEachNode(function (node) {
            _Page_NewSequence.push({ page_id: node.data.page_id, seq: node.rowIndex });
        });
        setPage_NewSequence(_Page_NewSequence);
    }, [Page_GridApi]);

    const Section_RowDragEnd = useCallback((e) => {
        let _Section_NewSequence = [];
        Section_GridApi.forEachNode(function (node) {
            _Section_NewSequence.push({ section_id: node.data.section_id, seq: node.rowIndex });
        });
        setSection_NewSequence(_Section_NewSequence);
    }, [Section_GridApi]);


    const Section_onGridReady = (params) => {
        setSection_GridApi(params.api);
    };

    useEffect(() => {
        if (UserDetail !== null) {
            getDashboardData();
        }
    }, [UserDetail]);

    const getDashboardData = async () => {
        try {
            getTabInfo().then(res => {
                if (res.status) {
                    let AllNavigationData = res.data;
                    let _pageList = [];
                    let _SectionList = [];
                    let IsSectionSelected = false;
                    AllNavigationData.forEach(pages => {
                        _pageList.push(pages)
                        if (String(pages.page_id) !== String("1")) {
                            if (!IsSectionSelected) {
                                pages.section.forEach(sections => {
                                    _SectionList.push(sections);
                                });
                                IsSectionSelected = true;
                                setSelectedPage(pages);
                            }
                        }
                    });
                    setPageList(_pageList);
                    setSectionList(_SectionList);
                } else {
                    toast.error(res.message);
                }
            });
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    const btnEditPage_onClick = async (params) => {
        try {
            getTabInfo().then(res => {
                if (res.status) {
                    let AllNavigationData = res.data;
                    let _SectionList = [];
                    AllNavigationData.forEach(pages => {
                        if (String(pages.page_id) === String(params)) {
                            pages.section.forEach(sections => {
                                _SectionList.push(sections);
                            });
                            setSelectedPage(pages);
                            setPageSaveScreenVisible(true);
                        }
                    });
                    setSectionList(_SectionList);
                } else {
                    toast.error(res.message);
                }
            });
        } catch (error) {
            toast.error("Something went wrong");
        }
    };
    const btnViewPage_onClick = async (params) => {
        try {
            getTabInfo().then(res => {
                if (res.status) {
                    let AllNavigationData = res.data;
                    let _SectionList = [];
                    AllNavigationData.forEach(pages => {
                        if (String(pages.page_id) === String(params)) {
                            pages.section.forEach(sections => {
                                _SectionList.push(sections);
                            });
                            setSelectedPage(pages);
                        }
                    });
                    setSectionList(_SectionList);
                } else {
                    toast.error(res.message);
                }
            });
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    const btnDeletePage_onClick = async (page_id) => {
        try {
            if (window.confirm("Are you sure you want to delete?")) {
                getTabInfo().then(async res => {
                    if (res.status) {
                        let AllNavigationData = res.data;
                        let _SectionList = [];
                        AllNavigationData.forEach(pages => {
                            if (String(pages.page_id) === String(page_id)) {
                                pages.section.forEach(sections => {
                                    _SectionList.push(sections);
                                });
                                setSelectedPage(pages);
                            }
                        });
                        if (_SectionList?.length > 0) {
                            toast.error("This page has section. Please delete section first");
                        } else {
                            postPageInfoDelete(page_id).then(res => {
                                if (res.status) {
                                    toast.success(res.data);
                                    getDashboardData();
                                } else {
                                    toast.error("Error: " + res.data);
                                }
                            });
                        }
                    } else {
                        toast.error(res.message);
                    }
                });

            }
        } catch (error) {
            toast.error("Error in deleting data");
        }
    };

    const btnEditSection_onClick = async (params) => {
        try {
            getTabInfo().then(res => {
                if (res.status) {
                    let AllNavigationData = res.data;
                    AllNavigationData.forEach(pages => {
                        pages.section.forEach(sections => {
                            if (String(sections.section_id) === String(params)) {
                                setSelectedSection(sections);
                                setSectionSaveScreenVisible(true);
                            }
                        });
                    });
                } else {
                    toast.error(res.message);
                }
            });
        } catch (error) {
            toast.error("Error in deleting data");
        }
    }

    const btnDeleteSection_onClick = async (section_id) => {
        try {
            if (window.confirm("Are you sure you want to delete?")) {
                getTabInfo().then(async res => {
                    if (res.status) {
                        let AllNavigationData = res.data;
                        let _ReportList = [];
                        AllNavigationData.forEach(pages => {
                            pages.section.forEach(sections => {
                                if (String(sections.section_id) === String(section_id)) {
                                    sections.report.forEach(report => {
                                        _ReportList.push(report);
                                    });
                                }
                            });

                        });
                        if (_ReportList.length > 0) {
                            toast.error("This section has report. Please delete report first");
                        } else {
                            postSectionInfoDelete(section_id).then(res => {
                                if (res.status) {
                                    toast.success(res.data);
                                    getDashboardData();
                                } else {
                                    toast.error("Error: " + res.data);
                                }
                            });
                        }
                    } else {
                        toast.error(res.message);
                    }
                });
            }
        } catch (error) {
            toast.error("Error in deleting data");
        }
    }
    const Image_onChange = async (file) => {
        try {
            if (file) {
                if (file.size > 50 && file.size < 1024 * 100) {//50b to 100kb
                    ImageUpload(file).then(async res => {
                        if (res.status) {
                            let _SelectedPage = { ...SelectedPage };
                            _SelectedPage.icon = res.data;
                            setSelectedPage(_SelectedPage);
                        } else {
                            toast.error(res.message);
                        }
                    });
                } else {
                    toast.error("Image size should be between 50b to 1kb");
                }
            }
        } catch (error) {
            toast.error("Something went wrong");
        }
    };
    const pageControl_onChange = (e) => {
        setSelectedPage({ ...SelectedPage, [e.target.name]: e.target.value });
    }

    const btnCreateNewPage_onClick = () => {
        setSelectedPage({
            "page_id": 0,
            "page_name": "",
            "home_flag": false,
            "slug": "",
            "icon": "",
            "seq": 0,
            "page_title": "",
            "section": []
        })
        setPageSaveScreenVisible(true);
    }

    useEffect(() => {
        if (SelectedPage !== null) {
            let _SelectedPage = { ...SelectedPage };
            let pageErrorMsg = [];
            if (_SelectedPage?.page_id === "") {
                pageErrorMsg.push("Page Id is required");
            }
            if (_SelectedPage?.page_name === "") {
                pageErrorMsg.push("Page Name is required");
            }
            if (_SelectedPage?.slug === "") {
                pageErrorMsg.push("Slug is required");
            }
            if (_SelectedPage?.icon === "") {
                pageErrorMsg.push("Icon is required");
            }
            if (_SelectedPage?.seq === "") {
                pageErrorMsg.push("Seq is required");
            }
            setPageErrorMessage(pageErrorMsg)
        }
    }, [SelectedPage]);

    const btnSavePage_onClick = async () => {
        try {
            let _SelectedPage = { ...SelectedPage };
            let obj = {
                "page_name": _SelectedPage.page_name,
                "home_flag": false,
                "slug": _SelectedPage.slug,
                "created_by": UserDetail?.user?.user_id,
                "updated_by": UserDetail?.user?.user_id,
                "seq": 0,
                "icon": _SelectedPage.icon,
                "page_title": _SelectedPage.page_name
            }
            if (String(_SelectedPage.page_id) === "0") {
                getTabInfo().then(async res => {
                    if (res.status) {
                        let AllNavigationData = res.data;
                        const pages = [...new Set(AllNavigationData.map(item => item.page_id))];
                        let maxPageId = (Math.max(...pages)) + 1;

                        obj.seq = maxPageId;
                        postPageInformationInsert(obj).then(PageInformation_res => {
                            if (PageInformation_res.status) {
                                toast.success(PageInformation_res.data.message);
                                setPageSaveScreenVisible(false);
                                getDashboardData();
                            } else {
                                toast.error("Error: " + PageInformation_res.data.message);
                            }
                        });
                    } else {
                        toast.error(res.message);
                    }
                });

            } else {
                obj.page_id = String(_SelectedPage.page_id);
                postPageUpdate(obj.page_id, obj).then(res => {
                    if (res.status) {
                        toast.success(res.data.message);
                        setPageSaveScreenVisible(false);
                        getDashboardData();
                    } else {
                        toast.error("Error: " + res.data.message);
                    }
                });
            }
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    const sectionControl_onChange = (e) => {
        setSelectedSection({ ...SelectedSection, [e.target.name]: e.target.value });
    }

    const btnCreateNewSection_onClick = () => {
        setSelectedSection({
            "section_id": 0,
            "page_id": 0,
            "section_name": "",
            "icon": "",
            "seq": 0,
            "report": []
        })
        setSectionSaveScreenVisible(true);
    }

    const btnSaveSection_onClick = async () => {
        try {
            let _SelectedPage = { ...SelectedPage };
            let _SelectedSection = { ...SelectedSection };
            let obj = {
                "section_id": String(_SelectedSection.section_id),
                "page_id": _SelectedPage.page_id,
                "section_name": _SelectedSection.section_name,
                "icon": _SelectedSection.icon,
                "seq": _SelectedSection.seq
            }
            if (String(_SelectedSection.section_id) === "0") {
                getTabInfo().then(async res => {
                    if (res.status) {
                        let AllNavigationData = res.data;
                        let AllSections = [];
                        AllNavigationData.forEach(pages => {
                            pages.section.forEach(sections => {
                                AllSections.push(sections.section_id);
                            });
                        });
                        obj.seq = (Math.max(...AllSections)) + 1;
                        postSectioninfoInsert(obj).then(res => {
                            if (res.status) {
                                toast.success(res.data.message);
                                getDashboardData();
                            } else {
                                toast.error("Error: " + res.data.message);
                            }
                            setSectionSaveScreenVisible(false);
                        });
                    } else {
                        toast.error(res.message);
                    }
                });
            } else {
                postSectionUpdate(_SelectedSection.section_id, obj).then(res => {
                    if (res.status) {
                        toast.success(res.data.message);
                        getDashboardData();
                    } else {
                        toast.error("Error: " + res.data.message);
                    }
                    setSectionSaveScreenVisible(false);
                });
            }
        } catch (error) {
            toast.error("Something went wrong");
        }
    }


    const btnSavePageSequence_onClick = async () => {
        try {
            let _Page_NewSequence = [...Page_NewSequence];
            let homePageSeqCheck = _Page_NewSequence.find(item => String(item.page_id) === "1");
            if (homePageSeqCheck.seq !== 0) {
                toast.error("Home page should be in first position");
            } else {
                postPageSequence(Page_NewSequence).then(res => {
                    if (res.status) {
                        toast.success(res.data.message);
                        setPage_NewSequence([]);
                        getDashboardData();
                    } else {
                        toast.error("Error: " + res.data.message);
                    }
                });
            }
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    const btnSaveSectionSequence_onClick = async () => {
        try {
            postSectionSequence(Section_NewSequence).then(res => {
                if (res.status) {
                    toast.success(res.data.message);
                    setSection_NewSequence([]);
                    getDashboardData();
                } else {
                    toast.error("Error: " + res.data.message);
                }
            });
        } catch (error) {
            toast.error("Something went wrong");
        }
    }



    return (
        <>
            <BackendTopMenu />
            <div className="mainContainer">
                <div className="row mt-2 mb-2">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                            <Link className={st.navigateItem} to={"/backend"}>Admin Home</Link>
                            <Link className={st.navigateItem} to={"/backend/pageList"}>Page List</Link>
                        </Breadcrumbs>
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col-sm-12 col-md-12 col-lg-7">
                        <div className={st.subSectionTitleContainer}>
                            <div className={st.pageTitle}>Page List</div>
                            <Button variant="contained" endIcon={<IoIosAddCircle />} size="small" onClick={btnCreateNewPage_onClick}>
                                Create Page
                            </Button>
                        </div>
                        <div style={containerStyle} className='aggrid-header'>
                            <div
                                style={gridStyle}
                                className="ag-theme-quartz"
                            >
                                <AgGridReact
                                    rowData={PageList}
                                    columnDefs={page_columns}
                                    defaultColDef={defaultColDef}
                                    rowDragManaged={true}
                                    onRowDragEnd={Page_RowDragEnd}
                                    onGridReady={Page_onGridReady}
                                />
                            </div>
                        </div>
                        <div className={st.subSectionBottomContainer}>
                            {
                                Page_NewSequence.length > 0 ?
                                    <Button variant="contained" endIcon={<FaRegSave />} size="small" onClick={btnSavePageSequence_onClick}>
                                        Save Page Sequence
                                    </Button> : null
                            }
                        </div>
                        <br />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-5">
                        <div className={st.subSectionTitleContainer}>
                            <div className={st.pageTitle}>{SelectedPage?.page_name} Section</div>
                            <Button variant="contained" endIcon={<IoIosAddCircle />} size="small" onClick={btnCreateNewSection_onClick}>
                                Create Section
                            </Button>
                        </div>
                        <div style={containerStyle} className='aggrid-header'>
                            <div
                                style={gridStyle}
                                className="ag-theme-quartz"
                            >
                                <AgGridReact
                                    rowData={SectionList}
                                    columnDefs={section_columns}
                                    defaultColDef={defaultColDef}
                                    rowDragManaged={true}
                                    onRowDragEnd={Section_RowDragEnd}
                                    onGridReady={Section_onGridReady}
                                />
                            </div>
                        </div>
                        <div className={st.subSectionBottomContainer}>
                            {
                                Section_NewSequence.length > 0 ?
                                    <Button variant="contained" endIcon={<FaRegSave />} size="small" onClick={btnSaveSectionSequence_onClick}>
                                        Save Section Sequence
                                    </Button> : null
                            }
                        </div>
                        <br />
                    </div>
                </div>
            </div>
            <ModalPopup IsVisible={PageSaveScreenVisible} onClose={() => { setPageSaveScreenVisible(false) }}>
                <div className={st.editRootContainer}>
                    <div className={st.editContainer}>
                        <div className={st.editHeaderContainer}>
                            <div className={st.editTitle}>
                                Save Page
                            </div>
                            <IoIosCloseCircle onClick={() => { setPageSaveScreenVisible(false) }} className={st.closeIcon} />
                        </div>
                        <div className={st.editBodyContainer}>
                            <div className={st.formContainer}>
                                <div className={st.formTitle}>Page Title</div>
                                <input type="text" placeholder='Page Name' name="page_name"
                                    value={SelectedPage.page_name} onChange={pageControl_onChange} className={st.formInput} />
                            </div>
                            <div className={st.formContainer}>
                                <div className={st.formTitle}>Page Slug</div>
                                <input type="text" placeholder='Page slug' name="slug"
                                    value={SelectedPage.slug} onChange={pageControl_onChange} className={st.formInput} />
                            </div>
                            <div className={st.formContainer}>
                                <div className={st.formTitle}>Page Icon</div>
                                <div className={st.fileUploader}>
                                    <FileUploader handleChange={Image_onChange} name="file"
                                        lable="Upload or drop a file right here" types={["PNG", "JPG", "JPEG"]} />
                                    {
                                        SelectedPage?.icon !== "" ?
                                            <Image src={BaseUrlAPI + "images" + SelectedPage?.icon + "?token=" + UserDetail?.user?.token}
                                                alt="Icon" className={st.pageIcon} />
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={st.editFooterContainer}>
                            <Button disabled={PageErrorMessage.length > 0} variant="contained" endIcon={<FaRegSave />} size="small"
                                onClick={btnSavePage_onClick}>
                                Save
                            </Button>
                        </div>
                    </div>
                </div>

            </ModalPopup>
            <ModalPopup IsVisible={SectionSaveScreenVisible} onClose={() => { setSectionSaveScreenVisible(false) }}>
                <div className={st.editRootContainer}>
                    <div className={st.editContainer}>
                        <div className={st.editHeaderContainer}>
                            <div className={st.editTitle}>
                                Save Section
                            </div>
                            <IoIosCloseCircle onClick={() => { setSectionSaveScreenVisible(false) }} className={st.closeIcon} />
                        </div>
                        <div className={st.editBodyContainer}>
                            <div className={st.formContainer}>
                                <div className={st.formTitle}>Page Name</div>
                                <input type="text" placeholder='Page Name' name="slug"
                                    disabled={true} value={SelectedPage.page_name} className={st.formInput} />
                            </div>
                            <div className={st.formContainer}>
                                <div className={st.formTitle}>Section Name</div>
                                <input type="text" placeholder='Name' name="section_name"
                                    value={SelectedSection.section_name} onChange={sectionControl_onChange} className={st.formInput} />
                            </div>
                        </div>
                        <div className={st.editFooterContainer}>
                            <Button variant="contained"
                                disabled={SelectedSection?.section_name === "" ? true : false}
                                endIcon={<FaRegSave />} size="small" onClick={btnSaveSection_onClick}>
                                Save
                            </Button>
                        </div>
                    </div>
                </div>

            </ModalPopup>
        </>
    );
};

export default PageList;