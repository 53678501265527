import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import parse from 'html-react-parser';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FaEdit } from "react-icons/fa";
import { IoIosAddCircle, IoMdTrash } from "react-icons/io";
import 'react-quill/dist/quill.snow.css';
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as _ from 'lodash';
import { UDContext } from '../../../App';
import { BaseUrlAPI, getNewsInfo, postNewsInfoDelete, postNewsUpdate } from '../../BaseModels/MasterData';
import { useContainerDimensions } from '../../BaseModels/ResizeWindow';
import BackendTopMenu from '../BackendTopMenu/BackendTopMenu';
import ImageLogo from './../../../images/ImageLogo.svg';
import st from './News.module.css';

const Image = ({ src, alt, ...props }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            <img src={src} alt={alt} {...props}
                style={loaded ? { ...props.style } : { ...props.style, display: 'none' }}
                onLoad={() => { setLoaded(true); }} />
            {loaded ? null : <img src={ImageLogo} alt={alt} {...props} />}
        </>

    );
};

const News = () => {
    const UserDetail = useContext(UDContext);
    const { width, height } = useContainerDimensions()
    const navigate = useNavigate();
    const [AllNewsData, setAllNewsData] = useState();
    const [IsGridLoading, setIsGridLoading] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const columnDefs = [
        {
            headerName: 'Title', field: 'title',
            cellRenderer: function (params) {
                return (
                    <>
                        {params.value}
                    </>
                )
            },
            sortable: true, filter: true
        },
        {
            headerName: 'Description', field: 'description',
            cellRenderer: function (params) {
                return (
                    <>
                        {parse(params.value)}
                    </>
                )
            },
            sortable: false, filter: false
        },
        {
            headerName: 'Image', field: 'image',
            cellRenderer: function (params) {
                if (params.value !== "") {
                    const token = localStorage.getItem('token');
                    if (token) {
                        return (
                            <>
                                <Image src={BaseUrlAPI + "images" + params.value + "?token=" + UserDetail?.user?.token}
                                    alt="Image" className={st.tableImage} />
                            </>
                        )
                    } else return <></>
                } else return <></>
            },
            sortable: false, filter: false
        },
        { headerName: 'Visible In Home Page ', field: 'news_type', sortable: true, filter: true, editable: !IsGridLoading },
        { headerName: 'Publish', field: 'public', sortable: true, filter: true, editable: true },
        { headerName: 'Added By', field: 'created_by', sortable: true, filter: true },
        { headerName: 'Added Date', field: 'created_date', sortable: false, filter: false },
        {
            headerName: 'Action', field: 'news_id',
            cellRenderer:
                function (params) {
                    return (
                        <>
                            <div className={st.tableActionButtonContainer}>
                                <div className={st.btnActionContainer}>
                                    <Tooltip title="Edit" placement="top">
                                        <IconButton onClick={() => { btnUpdate_onClick(params.value) }} className={st.btnAction}>
                                            <FaEdit />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className={st.btnActionContainer}>
                                    <Tooltip title="Delete" placement="top">
                                        <IconButton onClick={() => { btnDelete_onClick(params.value) }} className={st.btnAction}>
                                            <IoMdTrash />
                                        </IconButton>
                                    </Tooltip>
                                </div>

                            </div>
                        </>
                    )
                },
            sortable: false,
            filter: false,
            width: 110,
            pinned: 'right'
        }
    ];
    const defaultColDef = useMemo(() => {
        let obj = {
            width: 170,
            filter: true,
            editable: false,
            sortable: true
        }
        if (width > 992) {
            obj.flex = 1;
        }
        return obj;
    }, []);



    useEffect(() => {
        if (UserDetail !== null) {
            getAllNews();
        }
    }, [UserDetail]);


    const getAllNews = async () => {
        getNewsInfo().then(async (response) => {
            if (response.status) {
                if (response.data.length > 0) {
                    let _data = await response.data.map(function (news) {
                        news.news_type = news.news_type === "HighLevel" ? true : false;
                        return news;
                    })
                    let newData = await _data;
                    await setAllNewsData(_data);
                } else {
                    setAllNewsData([]);
                }
            }
        });
    }

    const btnAddNewNews_onClick = () => {
        navigate("/backend/news/0");
    }

    const onGridReady = useCallback((params) => {
        setGridApi(params.api)
    }, []);

    const onCellValueChanged = useCallback((event) => {
        let _AllNewsData = [...AllNewsData];
        let _AllNewsData_HighLevel = _AllNewsData.filter((news) => news.news_type === true);

        if (_AllNewsData_HighLevel.length > 5 && event.data.news_type) {
            toast.error("Only 5 landing page announcement is allowed");
            getAllNews();
            return;
        }
        let obj = _.cloneDeep(event.data);
        obj.news_type = obj.news_type ? "HighLevel" : "LowLevel";
        setIsGridLoading(true)
        postNewsUpdate(obj).then((response) => {
            if (response.status) {
                toast.success(response.data);
            } else {
                toast.error(response.message);
            }
            getAllNews();
            setIsGridLoading(false)
        });
    }, [gridApi, AllNewsData]);

    const btnUpdate_onClick = (news_id) => {
        navigate("/backend/news/" + news_id);
    }

    const btnDelete_onClick = async (news_id) => {
        if (window.confirm('Are you sure you wish to delete this item?')) {
            postNewsInfoDelete(news_id).then((response) => {
                if (response.status) {
                    toast.success(response.data);
                    setTimeout(() => {
                        getAllNews()
                    }, 1000);
                } else {
                    toast.error("Error: " + response.data);
                }
            });
        }
    }



    return (
        <>
            <BackendTopMenu />
            <div className="mainContainer">
                <div className="row mt-2">
                    <div className="col-lg-6">
                        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                            <Link className={st.navigateItem} to={"/backend"}>Admin Home</Link>
                            <Link className={st.navigateItem} to={"/backend/news"}>Announcement</Link>
                        </Breadcrumbs>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-6">
                        <div className={st.pageTitle}>Announcement</div>
                    </div>
                    <div className="col-lg-6">
                        <div className={st.topActionButtonContainer}>
                            <Button variant="contained" endIcon={<IoIosAddCircle />}
                                size="small" onClick={btnAddNewNews_onClick}>
                                Create
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-12">
                        <div className='aggrid-header'>
                            <div
                                style={{ height: (height - 180) + "px", minHeight: '300px', width: '100%' }}
                                className="ag-theme-quartz">
                                <AgGridReact
                                    rowData={AllNewsData}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    onGridReady={onGridReady}
                                    onCellValueChanged={onCellValueChanged}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer autoClose={1000} />
        </>
    );
};

export default News;
