import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// let baseUrl = 'https://e2eviz-development.amgen.com/api/';
// let baseUrl = "http://127.0.0.1:8080/api/";
let baseUrl = process.env.REACT_APP_API_BASE_URL;
let MatomoSiteId = process.env.WDS_SOCKET_PATH;

function getData(url) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(baseUrl + "api/" + url);
            if (!response.ok) {
                toast("Error: Posting Data to API", { type: 'error', autoClose: 1000 });
                // throw new Error('Failed to fetch data from API');
            }
            const data = await response.json();
            resolve(data);
        } catch (error) {
            console.error('Error fetching data from API:', error);
            reject(error);
        }
    });
}
function getIpAddress() {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch("https://api.ipify.org/?format=json");
            if (!response.ok) {
                toast("Error: Posting Data to API", { type: 'error', autoClose: 1000 });
                // throw new Error('Failed to fetch data from API');
            }
            const data = await response.json();
            resolve(data.ip);
        } catch (error) {
            console.error('Error fetching data from API:', error);
            reject(error);
        }
    });
}

function postData(url, data) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(baseUrl + "api/" + url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (!response.ok) {
                toast("Error: Posting Data to API", { type: 'error', autoClose: 1000 });
                // throw new Error('Failed to post data to API');
            }
            const responseData = await response.json();
            resolve(responseData);
        } catch (error) {
            console.error('Error posting data to API:', error);
            reject(error);
        }
    });
}

function saveException(user_id, exception_type, page_detail, exception_msg) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = {
                user_id: user_id,
                exception_type: "UI#" + exception_type,
                page_detail: page_detail,
                exception_msg: exception_msg
            };
            const response = await fetch(baseUrl + "api/" + "exception/save", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (!response.ok) {
                throw new Error('Failed to post data to API');
            }
            const responseData = await response.json();
            resolve(responseData);
        } catch (error) {
            console.error('Error posting data to API:', error);
            reject(error);
        }
    });
}
function getException() {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(baseUrl + "api/" + "exception/getAll");
            if (!response.ok) {
                throw new Error('Failed to fetch data from API');
            }
            const data = await response.json();
            resolve(data);
        } catch (error) {
            console.error('Error fetching data from API:', error);
            reject(error);
        }
    });
}

/**
 * make a GET request using fetch with optional parameters
 * @param {string} url 
 * @param {RequestInit} params Optional extra parameters for fetch.
 *  Accepts `queryParams` object.
 */
const getDataP = async (url, params = {queryParams:{token: ''}}) => {
    try {
        const queryString = new URLSearchParams(params.queryParams).toString();
        const response = await fetch(`${baseUrl}api/${url}?${queryString}`, params);
        if (!response.ok) {
            toast("Error: Posting Data to API", { type: 'error', autoClose: 1000 });
            throw new Error('Failed to fetch data from API');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data from API:', error);
        throw error;
    }
}

export { getData, getIpAddress, getException, postData, saveException, baseUrl, MatomoSiteId, getDataP };

