
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import * as _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { BsTicketDetailedFill } from "react-icons/bs";
import { FaEdit, FaRegSave } from "react-icons/fa";
import { IoIosAddCircle, IoIosCloseCircle, IoMdTrash } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UDContext } from '../../../App';
import CustomButton from '../../CustomElements/CustomButton/CustomButton';
import ModalPopup from '../../CustomElements/ModalPopup/ModalPopup';
import BackendTopMenu from '../BackendTopMenu/BackendTopMenu';
import {
    getDRT_ProjectList,
    getTabInfo,
    postDRT_ProjectInfoDelete,
    postDRT_ProjectInfoInsert,
    postDRT_ProjectInfoUpdate,
    postReportInfoDelete
} from './../../BaseModels/MasterData';
import { useContainerDimensions } from './../../BaseModels/ResizeWindow';
import st from './Dashboards.module.css';


const RedirectedButton = (props) => {
    const btnEdit_onClick = () => {
        props.Edit_Click(props.value);
    }
    const btnDelete_onClick = () => {
        props.Delete_Click(props.value);
    }
    return (
        <>
            <div className={st.tableActionButtonContainer}>
                <div className={st.btnActionContainer}>
                    <Tooltip title="Edit" placement="top">
                        <IconButton onClick={btnEdit_onClick} className={st.btnAction}>
                            <FaEdit />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className={st.btnActionContainer}>
                    <Tooltip title="Delete" placement="top">
                        <IconButton onClick={btnDelete_onClick} className={st.btnAction}>
                            <IoMdTrash />
                        </IconButton>
                    </Tooltip>
                </div>

            </div>
        </>
    )
};


function Dashboards() {
    const { width, height } = useContainerDimensions()
    const UserDetail = useContext(UDContext);
    const navigate = useNavigate();
    const [IsProjectListPopupVisible, setIsProjectListPopupVisible] = useState(false);
    const [gridApiProject, setGridApiProject] = useState(null);
    const [ProjectId, setProjectId] = useState("");
    const [ProjectName, setProjectName] = useState("");
    const [DrtProjectList, setDrtProjectList] = useState([]);
    const [AllDashboardData, setAllDashboardData] = useState();
    const [SearchText, setSearchText] = useState("");
    const [gridApi, setGridApi] = useState(null);
    const containerStyle = useMemo(() => ({ width: '100%', height: '380px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

    const columnDefsProject = [
        { headerName: 'Name', field: 'project_name', sortable: true, filter: true },
        {
            headerName: 'Action', field: 'project_id',
            cellRenderer:
                function (params) {
                    return (
                        <>
                            <div className={st.tableActionButtonContainer}>
                                <div className={st.btnActionContainer}>
                                    <Tooltip title="Edit" placement="top">
                                        <IconButton onClick={() => { btnEditProject_onClick(params.value) }} className={st.btnAction}>
                                            <FaEdit />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className={st.btnActionContainer}>
                                    <Tooltip title="Delete" placement="top">
                                        <IconButton onClick={() => { btnDeleteProject_onClick(params.value) }} className={st.btnAction}>
                                            <IoMdTrash />
                                        </IconButton>
                                    </Tooltip>
                                </div>

                            </div>
                        </>
                    )
                },
            sortable: false,
            filter: false,
            width: 110,
            pinned: 'right'
        }
    ];

    const [columnDefs, setColumnDefs] = useState([
        { field: 'page', headerName: 'Page', width: 170 },
        { field: 'section', headerName: 'Section', width: 100 },
        { field: 'title', headerName: 'Title', width: 230 },
        // { field: 'slug', headerName: 'Slug', width: 200 },
        { field: 'description', headerName: 'Description', width: 200 },
        { field: 'url', headerName: 'Tableau URL', width: 450 },
        {
            field: 'dashboardid',
            headerName: 'Action',
            cellRenderer: RedirectedButton,
            cellRendererParams: {
                Edit_Click: function (dashboardid) {
                    navigate(`/backend/dashboards/${dashboardid}`)
                },
                Delete_Click: async function (dashboardid) {
                    try {
                        if (window.confirm("Are you sure you want to delete this dashboard?")) {
                            postReportInfoDelete(dashboardid).then(res => {
                                if (res.status) {
                                    toast.success(res?.data);
                                    getDashboardData();
                                } else {
                                    toast.error("Error: " + res?.data);
                                }
                            });
                        }
                    } catch (error) {
                        toast.error("Something went wrong");
                    }
                },
            },
            sortable: false,
            filter: false,
            width: 75,
            pinned: 'right'
        }
    ]);
    const defaultColDef = useMemo(() => {
        let obj = {
            width: 170,
            filter: true,
            editable: false,
            sortable: true
        }
        if (width > 992) {
            obj.flex = 1;
        }
        return obj;
    }, []);

    useEffect(() => {
        if (UserDetail !== null) {
            getDashboardData();
            getDRTProjectListData();
        }
    }, [UserDetail]);

    const getDashboardData = async () => {
        try {
            getTabInfo().then(res => {
                if (res.status) {
                    let AllNavigationData = res.data;
                    let AllMenu = [];
                    let _AllDashboardData = [];
                    AllNavigationData.forEach(pages => {
                        let obgPage = {
                            "page_id": pages.page_id,
                            "pageName": pages.page_name,
                            "home_flag": pages.home_flag,
                            "slug": pages.slug,
                            "page_icon": pages.icon,
                            "data": []
                        }
                        pages.section.forEach(sections => {
                            let obgSection = {
                                "section_id": sections.section_id,
                                "page_id": sections.page_id,
                                "sectionName": sections.section_name,
                                "icon": sections.icon,
                                "seq": sections.seq,
                                "data": []
                            }
                            sections.report.forEach(reports => {
                                let obgDashboard = {
                                    "dashboardid": reports.report_id,
                                    "page_id": reports.page_id,
                                    "page": pages.page_name,
                                    "section_id": reports.section_id,
                                    "section": sections.section_name,
                                    "title": reports.title,
                                    "slug": reports.slug,
                                    "description": reports.description,
                                    "type": reports.report_type,
                                    "url": reports.url,
                                    "shared_url": reports.shared_url,
                                    "priority_feature": false,
                                    "height": reports.report_height,
                                    "top_tool_bar_visible": reports.top_tool_bar,
                                    "bottom_tool_bar_visible": reports.bottom_tool_bar_visible,
                                    "share_tableau_viz_visible": reports.share_tableau_viz_visible,
                                    "custom_views_visible": reports.custom_views_visible,
                                    "download_visible": reports.download_visible,
                                    "download_csv_visible": reports.download_csv_visible,
                                    "download_image_visible": reports.download_image_visible,
                                    "download_pdf_visible": reports.download_pdf_visible,
                                    "download_excel_visible": reports.download_excel_visible,
                                    "download_ppt_visible": reports.download_ppt_visible,
                                    "report_builder_visible": reports.report_builder_visible,
                                    "navigation_order": reports.seq,
                                }
                                obgSection.data.push(obgDashboard);
                                _AllDashboardData.push(obgDashboard)
                            });

                            obgPage.data.push(obgSection);
                        });
                        AllMenu.push(obgPage);
                    });
                    setAllDashboardData(_AllDashboardData);
                } else {
                    toast.error(res.message);
                }
            });
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    const getDRTProjectListData = async () => {
        try {
            getDRT_ProjectList().then(response => {
                if (response.status) {
                    let _data = response.data;
                    _data = _data.map(item => {
                        return { project_id: item.project_id, project_name: item.project_name, project_name_old: item.project_name };
                    });
                    setDrtProjectList(_data);
                }
            });
        } catch (error) {
            toast.error("Error in getting project list");
        }
    }



    const onGridReady = useCallback((params) => {
        setGridApi(params.api)
    }, []);

    const onFilterTextChange = useCallback((e) => {
        setSearchText(e.target.value);
        gridApi.setQuickFilter(e.target.value);
    }, [gridApi]);

    const txtProjectName_onChange = (e) => {
        let _ProjectName = e.target.value;
        setProjectName(_ProjectName);
        if (_ProjectName === "") {
            setProjectId("");
        }
    }

    const btnSaveProject_onClick = async () => {
        try {
            if (ProjectId === "") {
                let obj = {
                    "project_name": ProjectName
                }
                postDRT_ProjectInfoInsert(obj).then(response => {
                    if (response.status) {
                        toast.success(response.data);
                        setProjectId("");
                        setProjectName("");
                        getDRTProjectListData();
                        setIsProjectListPopupVisible(false);
                    }
                });
            } else {
                let obj = {
                    "project_id": ProjectId,
                    "project_name": ProjectName
                }
                postDRT_ProjectInfoUpdate(obj).then(response => {
                    if (response.status) {
                        toast.success(response.data);
                        setProjectId("");
                        setProjectName("");
                        getDRTProjectListData();
                        setIsProjectListPopupVisible(false);
                    }
                });
            }
        } catch (error) {
            toast.error("Error in saving project");
        }
    }

    const btnEditProject_onClick = async (project_id) => {
        setProjectId(project_id);
        let _Project = DrtProjectList.find(x => x.project_id === project_id);
        if (_Project !== undefined) {
            setProjectName(_Project.project_name);
        }
    }

    const btnDeleteProject_onClick = async (project_id) => {
        try {
            if (window.confirm("Are you sure you want to delete?")) {
                postDRT_ProjectInfoDelete(project_id).then(response => {
                    if (response.status) {
                        toast.success(response.data);
                        getDRTProjectListData();
                        setIsProjectListPopupVisible(false);
                    }
                });
            }
        } catch (error) {
            toast.error("Error in deleting project");
        }
    }


    const Project_onGridReady = useCallback((params) => {
        setGridApiProject(params.api)
    }, []);

    const Project_onCellValueChanged = useCallback((event) => {
        //event.data
    }, []);



    return (
        <>
            <BackendTopMenu />
            <div className="mainContainer">
                <div className="row mt-2 mb-2">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                            <Link className={st.navigateItem} to={"/backend"}>Admin Home</Link>
                            <Link className={st.navigateItem} to={"/backend/dashboards"}>Reports</Link>
                        </Breadcrumbs>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        {/* <div className={st.topRightButtonContainer}>
                            <Tooltip title="Manage Digital Review Tracker Report List">
                                <Button variant="contained" size="medium" endIcon={<BsTicketDetailedFill />}
                                    sx={{ textTransform: 'capitalize' }} onClick={() => setIsProjectListPopupVisible(true)}>
                                    Manage DRT Report List
                                </Button>
                            </Tooltip>
                        </div> */}
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className={st.pageTitle}>Reports</div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        {/* <div className={st.topRightButtonContainer}>
                            <CustomButton onClick={() => { navigate("/backend/dashboards/0"); }} type="primary" icon={<IoIosAddCircle />}>Create Report</CustomButton>
                        </div> */}
                        <div className={st.topRightButtonContainer}>
                            <Tooltip title="Manage Digital Review Tracker Report List">
                                <Button variant="contained" size="small" endIcon={<BsTicketDetailedFill />}
                                    className={'parent'}
                                    sx={{ textTransform: 'capitalize' }} onClick={() => setIsProjectListPopupVisible(true)}>
                                    Manage DRT Report List
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className="row mb-1">
                    <div className="col-lg-3">
                        <input type="text" placeholder="Search"
                            disabled={!(AllDashboardData?.length > 0)}
                            className={st.searchControl} value={SearchText} onChange={onFilterTextChange} />
                    </div>
                    <div className="col-lg-6">
                        <CustomButton onClick={() => { navigate("/backend/dashboards/0"); }} type="primary" icon={<IoIosAddCircle />}>Add New Report</CustomButton>
                    </div>
                    <div className="col-lg-3">
                        <div className={st.totalCount}>
                            Total Reports: {AllDashboardData?.length}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className='aggrid-header'>
                            <div
                                style={{ height: (height - 250) + "px", minHeight: '300px', width: '100%' }}
                                className="ag-theme-quartz">
                                <AgGridReact
                                    rowData={AllDashboardData}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    onGridReady={onGridReady}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalPopup IsVisible={IsProjectListPopupVisible} onClose={() => setIsProjectListPopupVisible(false)}>
                <div className={st.projectRootContainer}>
                    <div className={st.projectContainer}>
                        <div className={st.projectHeaderContainer}>
                            <div className={st.projectHeader}>DRT Project</div>
                            <IoIosCloseCircle className={st.btnClose} onClick={() => setIsProjectListPopupVisible(false)} />
                        </div>
                        <div className={st.projectBodyContainer}>
                            <div className={st.addProjectContainer}>
                                <input type="text" placeholder="Project Name" className="form-control form-control-sm" value={ProjectName} onChange={txtProjectName_onChange} />
                                <Button variant="contained" size="small" startIcon={<FaRegSave />}
                                    disabled={ProjectName === "" ? true : false}
                                    color={ProjectId === "" ? "primary" : "secondary"}
                                    onClick={btnSaveProject_onClick}>
                                    {ProjectId === "" ? "Add" : "Save"}
                                </Button>
                            </div>
                            <br />
                            <div className='aggrid-header'>
                                <div style={{ height: (height / 2) + "px", minHeight: '300px', width: '100%' }} className="ag-theme-quartz">
                                    <AgGridReact
                                        rowData={DrtProjectList}
                                        columnDefs={columnDefsProject}
                                        defaultColDef={{
                                            width: 170,
                                            filter: true,
                                            editable: false,
                                            sortable: true,
                                            flex: 1,
                                        }}
                                        onGridReady={Project_onGridReady}
                                        onCellValueChanged={Project_onCellValueChanged}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={st.projectFooterContainer}></div>
                    </div>
                </div>
            </ModalPopup>
        </>
    );
}

export default Dashboards;
