import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { toPng } from 'html-to-image';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import { FaRegStar, FaShareSquare, FaStar } from "react-icons/fa";
import { IoReloadCircle, IoShareSocialSharp } from "react-icons/io5";
import Iframe from 'react-iframe';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UDContext } from '../../../../App';
import { postFavReportDelete, postFavReportInsert } from '../../../BaseModels/MasterData';
import TopMenu from '../../TopMenu/TopMenu';
import DashboardComment from '../DashboardComment/DashboardComment';
import st from './EmbedFromOtherSource.module.css';

const EmbedFromOtherSource = ({ DashboardDetail }) => {
    const UserDetail = useContext(UDContext);
    const elementRef = useRef(null);

    const [TableauDashboardDetail, setTableauDashboardDetail] = useState(null);
    const [IsFavorite, setIsFavorite] = useState(false);
    const [IsReportBuilderOpen, setIsReportBuilderOpen] = useState(false);
    const [IsDownloadOptionOpen, setIsDownloadOptionOpen] = useState(false);
    const screen1 = useFullScreenHandle();
    const [IsFullScreen, setIsFullScreen] = useState(false);
    const reportChange = useCallback((state, handle) => {
        setIsFullScreen(state)
    }, [screen1]);


    useEffect(() => {
        if (DashboardDetail) {
            setTableauDashboardDetail(DashboardDetail);
            if (UserDetail?.AllFavorites?.length > 0) {
                let FavoriteDashboards = UserDetail?.AllFavorites;
                let check = FavoriteDashboards.filter((item) => item.dashboardid === DashboardDetail.dashboardid);
                if (check.length > 0) {
                    setIsFavorite(true);
                } else {
                    setIsFavorite(false);
                }
            }
        }
    }, [DashboardDetail]);

    const btnIsFavorite_onClick = async () => {
        try {
            setIsFavorite(!IsFavorite);
                let favoriteObj = { user_id: UserDetail?.user?.user_id, dashboardid: TableauDashboardDetail?.dashboardid };
                if (!IsFavorite) {
                    postFavReportInsert( TableauDashboardDetail?.dashboardid, UserDetail?.user?.user_id).then((response) => {
                        if (response.status) {
                            toast.success(response.data);
                        }
                    });
                } else {
                    postFavReportDelete( TableauDashboardDetail?.dashboardid, UserDetail?.user?.user_id).then((response) => {
                        if (response.status) {
                            toast.success(response.data);
                        }
                    });
                }
        } catch (error) {
            toast.error("Error: Favorite Dashboard", { type: 'error', autoClose: 1000 });
        }
    }
    const btnDownloadOptionToggle = () => {
        setIsDownloadOptionOpen(!IsDownloadOptionOpen);
    }
    const btnDownload_csv_onClick = async () => {

    }
    const btnDownload_excel_onClick = async () => {

    }
    const htmlToImageConvert = () => {
        toPng(elementRef.current, { cacheBust: false })
            .then((dataUrl) => {
                const link = document.createElement("a");
                link.download = "ImageData.png";
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => {
                toast.error("Error: Image Download");
            });
    };

    const btnDownload_image_onClick = async () => {
        htmlToImageConvert();
    }
    const btnDownload_pdf_onClick = async () => {

    }
    const btnDownload_ppt_onClick = async () => {

    }



    return (
        <>
            <FullScreen handle={screen1} onChange={reportChange}>
                <TopMenu />
                <div className="mainContainer">
                    <div className="row mt-3 mb-3">
                        <div className="col-lg-6">
                            <span className={st.dashboardTitleName}>
                                {DashboardDetail?.title}
                            </span>
                        </div>
                        <div className="col-lg-6">
                            <div className={st.tableauTopActionButtonContainer}>
                                <div className={st.tableauTopActionButtonIconContainer}>
                                    <Tooltip title="Favorite">
                                        <IconButton onClick={btnIsFavorite_onClick}>
                                            <FaStar style={{ display: !IsFavorite ? 'none' : 'block' }} className={`${st.tableauTopActionButtonIcon} ${st.tableauTopActionButtonIconActive}`} />
                                            <FaRegStar style={{ display: IsFavorite ? 'none' : 'block' }} className={st.tableauTopActionButtonIcon} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                {/* <div className={TableauDashboardDetail?.download_visible ? st.tableauTopActionButtonIconContainer : st.tableauTopActionButtonIconContainerDisplayNone}>
                                    <FaDownload className={st.tableauTopActionButtonIcon} onClick={btnDownloadOptionToggle} />
                                    <div className={st.downloadItemRootContainer} style={{ display: IsDownloadOptionOpen ? 'block' : 'none' }}>
                                        <div className={st.downloadItemContainer} onClick={btnDownload_csv_onClick} style={{ display: TableauDashboardDetail?.download_csv_visible ? 'flex' : 'none' }}>
                                            <FaFileCsv className={st.downloadItemIcon} />
                                            <div className={st.downloadItemTitle}>CSV</div>
                                        </div>
                                        <div className={st.downloadItemContainer} onClick={btnDownload_excel_onClick} style={{ display: TableauDashboardDetail?.download_excel_visible ? 'flex' : 'none' }}>
                                            <AiFillFileExcel className={st.downloadItemIcon} />
                                            <div className={st.downloadItemTitle}>Excel</div>
                                        </div>
                                        <div className={st.downloadItemContainer} onClick={btnDownload_image_onClick} style={{ display: TableauDashboardDetail?.download_image_visible ? 'flex' : 'none' }}>
                                            <FaImage className={st.downloadItemIcon} />
                                            <div className={st.downloadItemTitle}>Image</div>
                                        </div>
                                        <div className={st.downloadItemContainer} onClick={btnDownload_pdf_onClick} style={{ display: TableauDashboardDetail?.download_pdf_visible ? 'flex' : 'none' }}>
                                            <FaFilePdf className={st.downloadItemIcon} />
                                            <div className={st.downloadItemTitle}>PDF</div>
                                        </div>
                                        <div className={st.downloadItemContainer} onClick={btnDownload_ppt_onClick} style={{ display: TableauDashboardDetail?.download_ppt_visible ? 'flex' : 'none' }}>
                                            <RiFilePpt2Fill className={st.downloadItemIcon} />
                                            <div className={st.downloadItemTitle}>PPT</div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className={st.tableauTopActionButtonIconContainer} >
                                    <Tooltip title="Full Screen">
                                        <IconButton>
                                            <AiOutlineFullscreen className={st.tableauTopActionButtonIcon} style={{ display: IsFullScreen ? 'none' : 'block' }} onClick={screen1.enter} />
                                            <AiOutlineFullscreenExit className={`${st.tableauTopActionButtonIcon} ${st.tableauTopActionButtonIconActive}`} style={{ display: !IsFullScreen ? 'none' : 'block' }} onClick={screen1.exit} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className={st.tableauTopActionButtonIconContainer}>
                                    <Tooltip title="Reload">
                                        <IconButton onClick={() => window.location.reload(false)}>
                                            <IoReloadCircle className={st.tableauTopActionButtonIcon} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className={st.tableauTopActionButtonIconContainer}>
                                    <Tooltip title="Share Report">
                                        <IconButton onClick={() => { navigator.clipboard.writeText(TableauDashboardDetail?.url); toast("Report url copied"); }}>
                                            <FaShareSquare className={st.tableauTopActionButtonIcon} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className={st.tableauTopActionButtonIconContainer}>
                                    <Tooltip title="Share Page">
                                        <IconButton onClick={() => { navigator.clipboard.writeText(window.location.href); toast("Dashboard url copied"); }}>
                                            <IoShareSocialSharp className={st.tableauTopActionButtonIcon} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div ref={elementRef}>
                                {/* {TableauDashboardDetail ? (
                                    
                                    <iframe src={TableauDashboardDetail?.url} title="Embedded Content" height={TableauDashboardDetail?.height + "px"} width="100%" />
                                ) : (
                                    <p>Loading...</p>
                                )} */}
                                {TableauDashboardDetail ? (
                                    <Iframe url={TableauDashboardDetail?.url} width="100%" height={TableauDashboardDetail?.height + "px"} />
                                ) : (
                                    <p>Loading...</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <DashboardComment DashboardDetail={TableauDashboardDetail} />
            </FullScreen>
        </>
    );
};

export default EmbedFromOtherSource;
